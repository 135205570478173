@import "_brand";

.PatientInformationContainer {
  //display: flex;
  font-family: $brand_font;
}

.PatientInformationTitle{
    color: $brand_black;
    font: $brand_font16_bold;
    margin-bottom: 0;
}

.textPatientInformation{
  display: flex;
  justify-content: space-between;
  width: 150px;
  height: 20px;
  .value{
    color: $brand_black;
    font: $brand_font13;
  }

}
.thumbnailContainer{
    display: flex;
    width: 150px;
    height: 150px;
    border: 1px solid #000;
    border-radius: 5px;
    overflow: hidden;
    margin: 10px 20px 10px 0;
}

.informationContainer{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  gap: 10px;
  margin-top: 20px;
  margin-right: 50px;

}
