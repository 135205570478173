@import "_brand";

.custom-form-to-providers-container {
    & .css-2rbg70-MuiPaper-root-MuiDialog-paper {
        border: 5px solid $brand_darkblue;
    }

    & .custom-form-to-providers-container__title {
        font: $brand_header;
        text-align: center;
        margin-bottom: 1rem;
        min-height: 84px;
        background: #f7fafc 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 4px #0000004d;
        border: 3px solid #ced6df;
        border-radius: 6px;
        opacity: 1;
    }

    & .custom-form-to-providers-container__content {
        width: 1200px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        & .custom-form-to-providers-container__content__grid {
            display: flex;
            justify-content: center;
            gap: 15rem;
            align-items: flex-end;
            width: 100%;
            min-height: 84px;
            background: #f7fafc 0% 0% no-repeat padding-box;
            box-shadow: 0px 2px 4px #0000004d;
            border: 3px solid #ced6df;
            border-radius: 6px;
            opacity: 1;
            margin-bottom: 1rem;
        }
    }
}

.List {
    border: 3px solid $brand_darkblue;
}

.ListOval {
    border: 3px solid $brand_darkblue;
    height: 400px;
    width: 400px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
