@import "_brand";

.modalContainer {
    display: flex;
    justify-content: center;
}

.modalQuestion {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
    width: 1333px;
    background-color: $brand_white;
    box-shadow: 1px 1px 10px 1px $brand_black;
}

.svc-creator {
    height: 90% !important;
}
