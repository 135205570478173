@import "_brand";
@import "_global";

.gridHeader{
  min-height: 84px;
  background: #F7FAFC 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #0000004D;
  border: 3px solid #CED6DF;
  border-radius: 6px;
  opacity: 1;

  .title{
    text-align: left;
    font: $brand_header;
    letter-spacing: 0px;
    color: $brand_darkblue;
    opacity: 1;
  }

  .search{
    padding: 0 16px;
    box-shadow:  3px 0px 0px 0px $brand_darkblue, -3px 0px 0px 0px $brand_darkblue;
  }
 
}

.filter {
  height: 0px;
  //box-shadow:  0px -3px 0px 0px $brand_darkblue;
  background: #F7FAFC 0% 0% no-repeat padding-box;
  //box-shadow: 0px 2px 4px #0000004D;
  border: 3px solid #CED6DF;
  border-radius: 6px;
  opacity: 1;
  display:flex;
  flex-direction: column;
  justify-content: center;
  display: none;
  
  .filters{
    height:80%;
  }
  .buttons{
    height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: right;
  }
  
  &.expanded {
    height:300px;
    z-index: 100;
    display: flex;
  } 
}

.searchHeader{
  min-height: 84px;
  margin: 0 0 0 0;
  width: calc(100vw - 370px);
  background: #F7FAFC 0% 0% no-repeat padding-box;
  position: fixed;
  box-shadow: 0px 2px 4px #0000004D;
  border: 3px solid #CED6DF;
  border-radius: 6px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 200;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  padding: 20px 35px;
  display:none;
  &.expanded{
    transform: translateX(0);
    width: calc(100vw - 370px);
    display: flex;
  }

  .searchImg{   
    width:65px; 
    box-shadow: 1px 0px 0px 0px $brand_darkblue;
    cursor: pointer;
    .icon{
      height:48px;
      width:48px;
    }
  }
  .searchTextDiv {
    align-self: center;
    .searchText {
      width: 100%;
      text-align: left;
      font: $brand_font_xl_italic;
      letter-spacing: 0px;
      background: #F7FAFC;
      border: 0px;
      opacity: 1;
      padding-left: 30px;
      .MuiOutlinedInput-root {
        font: $brand_font_xl_italic;
      }
    }
  }

  .clearResults{
    width:120px;
    text-align: right;
    font: normal normal bold 16px/12px Roboto;
    letter-spacing: 0px;
    color: $brand_darkblue;
    opacity: 1;
    margin-right: 30px;
    cursor: pointer;
  }

  .close{
    box-shadow: -1px 0px 0px 0px $brand_darkblue;
    width:110px;
    width: 42px;
    height: 42px;
    cursor: pointer;
   // transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    /* UI Properties */
    opacity: 1;
    img {
      margin-top: 8px;
      margin-left: 30px;
    }
  }
}