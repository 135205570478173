@import "_brand";

.dataGrid{
  &.standard{
    height: calc( 100vh - 140px);
  }
      
    //height: 100%;
    min-height: 500px;
    margin-bottom: 10px;
    margin-left: 5px; 
    margin-right: 5px;
    text-align: 'center';
    z-index: 20;
    background-color: $brand_white;
    font: $brand_font16;
}

.detailPanel {
  height:unset;
}

.patientDetails {
    padding: 20px;
}

.colHeader{
    font: $brand_font16_bold;
    color: $brand_darkblue;
}