@import "_brand";

.nav{
    height: 98vh;
    min-width: 300px;
    max-width: 300px;
    background: #1B3E6F 0% 0% no-repeat padding-box;
    
    box-shadow: 3px 0px 6px #00000080;
    border-radius: 0px 6px 6px 0px;
    opacity: 1;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    color: #FFFFFF;
    text-align:center;
    padding: 31px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .leftnav-header{
        height: 125px;
        opacity: 1;
        box-shadow:  0px 1px 0px 0px #ffffff;
        #logo {
            background: url("~/src/imgs/Vivos-Logo.svg") no-repeat center;
            padding: 0;
            margin-top: 11px;
            margin-bottom: 31px;
            height:48px;
            width:260px;
            fill:#FFFFFF;
        }
        cursor: pointer;
    }

    .navSection{       
        border-radius: 3px;
        opacity: 1;
        margin: 20px 0px;        
        
        .highlighted{
            width: 160px;
            height: 16px;
            font: $brand_font16_italic;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
        }
        .normal {
            width: 160px;
            height: 16px;
            opacity: 1;
        }

    }

    .pre-footer{
        
        height: 75px;
        font: normal normal 300 12px/14px Proxima Nova;
        letter-spacing: 0px;
        color: $brand_white;
        opacity: 1;
        bottom: 15px;
        padding: 15px 0 0 0; 
        margin-bottom: 15px;
        margin-top: -30px;
        width: 80%;
        position: absolute;
        bottom: 35px;
    }

    .footer{
        height: 12px;
        font: normal normal 300 12px/14px Proxima Nova;
        letter-spacing: 0px;
        color: $brand_white;
        opacity: 1;
        box-shadow:  0px -1px 0px 0px $brand_white;
        position: absolute;
        bottom: 15px;
        padding: 15px 0 0 0; 
        margin-bottom: 15px;
        width: 80%;
    }
}

.contract {
    transform: translate(0px);
    transition: transform 0.5s ease-out;
    display: block;
    min-width: 100px;
    max-width: 100px;

    .leftnav-header {
        #logo{
            background: url("~/src/imgs/Vivos-Heart.svg") no-repeat center;
            width: 54px; /*or your image's width*/
            height: 47px; /*or your image's height*/
            margin: 0;
            padding: 0;
        }
    }

    .footer{
        box-shadow:  0px -1px 0px 0px #ffffff;
        color: $brand_darkblue;
        width: 48px;
        .label {
            display:none;
        }
    }
}