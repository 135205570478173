@import "_brand";

.uploadArea {

    .TextBox,
    .DateBox {
        width: 100%;
        border: 2px solid $brand_bordergrey;

        &.small {
            width: 150px;
        }

        &:hover {
            background-color: $brand_hoverblue;
        }

        &:focus-within {
            background-color: $brand_hoverblue;
        }
    }

    .cropper {
        justify-content: center;
        padding-left: 30px;
    }

    .BtnTopLeftCornerALT {
        right: -209px;
        top: 25px;
    }
}