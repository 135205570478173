.date-box {
    display: flex; 
    align-items: center; 
    padding-top: 20px;
    font: $brand_font16_bold;
    color: $brand_darkgrey;
    margin: 15px;
}

.center-box {
    display:flex; 
    align-content:center; 
    justify-content:right; 
    align-content: baseline;
}

.claim-info {
    color: $brand_blue;
}

.claim-data {
    font: $brand_font16_bold;
    color: $brand_darkgrey;
    font-size: x-large;
}

.claim-container {
    padding: 10px;
}

.box-container {
    margin: 5px;
    border: 3px solid $brand_lightgrey;
    border-radius:6px   
}