@import "_brand";

.addSessionsModal {

    height: 450px;
    width: 600px;
    min-width: 550px;
    padding: 10px;
    justify-content: space-between;

    label {
        padding-left: 15px;
    }
}

.addSessionsFillWidth {
    width: 100%;
    //margin-top: 25px;
}

.addSessionsGrouping {
    justify-content: space-between;
    //border: 0px 1px 1px 1px solid $brand_grey;
    padding: 15px;
}

.addSessionsBetweenInput {
    width: 3em;
    justify-content: center;
    text-align: center;
    text-justify: center;
}

.redTextNotes {
    color: red !important;

    &>div>input {
        color: red !important;
    }
    &>div>textarea {
        color: red !important;
    }
    &>div>span {
        color: red !important;
    }
}