.daysInQueue {
    &.greenNumber {
      color: #00ff40;
      font-size: 1.2em;
    }
  
    &.orangeNumber {
      color: #f7de00;
      font-size: 1.2em;
    }

    &.redNumber {
        color: #ff0f0f;
        font-size: 1.2em;
      }
  }