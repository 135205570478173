@import "_brand";

.modal {
    background: $brand_white 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #00000080;
    border: 3px solid $brand_white;
    border-radius: 3px;
    opacity: 1;
    //width: 90%;
    height: 800px;
}

.formSectionLabel {
    margin-left: 25px;
    font: $brand_font_large_bold;
}

.formLabel {
    color: $brand_blue;
}

.formBasics {
    .MuiFormControl-root {
        width: 90%;
    }

    .TextBox,
    .DateBox {
        width: 100%;
        border: 2px solid $brand_bordergrey;

        &.small {
            width: 100px;
        }

        &:hover{
            background-color: $brand_hoverblue;
        }
         &:focus-within{
            background-color: $brand_hoverblue;
        }
    }

    outline-color: none;
    outline-style: none;
    outline-width: 0px;

    fieldset: {
        border-width: 0px;
        border-style: solid;
        width: 100%;
    }
}

.formLabel {
    color: $brand_blue;
}


.dot {
    height: 16px;
    width: 16px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;

    &.ts0 {
        background-color: grey;
    }

    &.ts1 {
        background-color: red;
    }

    &.ts2 {
        background-color: green;
    }

    &.mc0 {
        background-color: grey;
    }

    &.mc1 {
        background-color: red;
    }

    &.mc2 {
        background-color: orange;
    }

    &.mc3 {
        background-color: green;
    }
}