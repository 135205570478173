@import "_brand";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page {
  display: flex;
  flex-direction: column;
}
.pageContent {
  height: 100%;
  margin: 20px;
  width: calc(100vw - 370px);
  &.small {
    width: calc(100vw - 170px);
  }
}

.content {
 // height: 100vh;
  position: relative;
  margin: 0px 20px;
  width: calc(100vw - 370px);
  &.small {
    width: calc(100vw - 170px);
  }
  //min-width: 1034px;
}
.rowContainer {
  display: flex;
  flex-direction: row;
}

.colContainer {
  display: flex;
  flex-direction: column;
  div {
    margin-bottom: 5px;
  }
}

button {
  width: auto;
}

.mainRow {
  width: 100vw;
  margin: 0 0 0 10px;
}

.w30 {
  width: 30vw;
}

.mainRow {
  // width: 100vw;
  margin: 0 0 0 10px;
}

.w100 {
  //width:100vw;
  margin: 20px 15px;
}

.modalHeader {
  background: #f7fafc 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px #00000080;
  border-radius: 3px;
  opacity: 1;
  height: 84px;
  align-self: center;
  //padding:20px;

  .title {
    font: normal normal 900 24px/29px Proxima Nova;
    letter-spacing: 0px;
    color: #050505;
    opacity: 1;
    line-height: 84px;
  }

  .seperator {
    box-shadow: 3px 0px 0px 0px #1b3e6f;
    margin: 0 10px;
    width: 3px;
    height: 32px;
  }
}
