.filterOption{
    
  display:flex;
  flex-direction: row;
  width: auto;
  //justify-content: space-between;  

    .selectBox{
        margin: 15px;
    }

    .filterName{
        margin: 15px;
    }

    .filterOptions{
        margin: 15px;
        &.disabled {
            pointer-events: none;
            opacity: 0.7;
        }
    }
    .cbx {
        padding: 0px;
    }
}