@import "_brand";

.CustomFormBuilder {
    font-family: $brand_font;
}

.SectionsQuestions {
    display: flex;
    flex-direction: column;
    width: 300px;
    //background: $brand_darkblue;
    z-index: 900;

    .list {
        padding: 22px;
        height: 85vh;
        overflow: auto;

        .heading {
            color: $brand_white;
            margin-bottom: 16px;
            font-weight: 500;
        }
    }
}

.Section {
    padding: 16px;
    user-select: none;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    color: $brand_white;
    background: $brand_blue;
    border-radius: $brand_grey;
}
.editor-layout {
    height: 100vh;
    margin-left: 360px;
}

.editor-cover {
    height: 180px;
    background: $brand_blue;
}

.editor-cover > .container {
    display: flex;
    align-items: flex-end;
    height: 100%;
    margin: 0 auto;
    padding-bottom: 56px;
    width: 720px;
}

.editor-cover > .container > .text {
    width: 100%;
    color: $brand_white;
    opacity: 0.75;
    font-weight: 300;
}

.CustomForm {
    position: relative;
    min-width: 100%;
    padding-left: 360px;

    .CustomForm-list {
        height: 100%;
        min-height: 100vh;
        background-color: $brand_white;
        border: 5px solid $brand_black;
    }

    .Section-list {
        user-select: none;
        color: $brand_black;
        border-top: 1px solid $brand_black;
        border-bottom: 1px solid $brand_black;
    }
}

.CustomSectionName {
    font: $brand_font16;
    margin-bottom: 8px;
    font-weight: bold;
    border-top: 1px solid $brand_black;
    border-bottom: 1px solid $brand_black;
}

.SubsectionsName {
    font: $brand_font16;
    margin-bottom: 8px;
    font-weight: bold;
}

.QuestionName {
    font: $brand_font16;
    margin-bottom: 8px;
    font-weight: bold;
    font-weight: bold;
}

.QuestionInputContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}

.colGroupHeader {
    width: 20%;
    justify-self: flex-end;
}

.QuestionDateTime {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}

.QuestionMultiLineTextContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    grid-column-start: 1;
    grid-column-end: 3;
    width: 50%;
    margin: 20px;
}

.Subsections {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.SubsectionColum {
    margin: 20px;
}

.SubsectionQuestions {
    display: grid;
}

.ColGroupHeaderContainer {
    display: flex;
    justify-content: space-between;
}

.ColGroupHeader {
    font: $brand_font16;
    margin-bottom: 8px;
}
