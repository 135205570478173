@import "_brand";

.visitModalContainer {
    padding: 0px;
    overflow: hidden;
    width: 100%;

    .MuiDialogContent-root {
        padding: 0px;
    }

    .visitHeader {
        margin: 0px;
        padding: 0px;
    }

    .visitTabContainer {
        padding: 25px 0px;
    }

    .applianceImage {
        .uploadImgContainer {
            margin-left: 0px;
        }

        .cropper {
            justify-content: flex-start;
            padding-left: 0px;
        }

        .BtnTopRightCorner {
            //position: relative;
            right: -166px;
            top: 25px;
            z-index: 19;
            background-color: $brand_white;
            border: 3px solid $brand_red;
            border-radius: 20px;
        }
    }
}