.box-claim-container {
    margin: 14px;
    padding: 14px;
    border: 3px solid $brand_lightgrey;
    border-radius:6px   
}

.box-claim-container-disabled {
  margin: 14px;
  padding: 14px;
  border: 3px solid $brand_lightgrey;
  border-radius:6px;
  pointer-events: none; 
}

.claim-data-title {
    font: $brand_font16_bold;
    color: $brand_darkgrey;
    font-size: x-large;
    padding: 7px 7px 10px 0px;
}

.claimuploadImgContainer {
    margin: 10px;
    height: 20px;
    width: 25px;
    border: 1px solid $brand_darkergrey;
    align-items: center;
    justify-content: center;
    text-align: center;
    font: $brand_font12;
  
  
    &.MuiGrid-container {
      height: 120px;
      width: 145px;
    }
  
    .uploadImg {
      height: 113px;
      width: 104px;
      left: 5px;
    }
  
    .uploadFile {
      &.MuiSvgIcon-root {
        height: 100px;
        width: 125px;
        fill: $brand_statusnavyblue;
      }
    }
}
  
.claimuploadImg {
  margin: 8px;
  height: 50px;
  width: 5px;
  border: 1px solid $brand_darkergrey;
  align-items: center;
  justify-content: center;
  text-align: center;
  font: $brand_font12;
}

.claim-textarea {
    border: 1px solid $brand_darkergrey;
}

.claim-save-button {
    display: flex;
    justify-content: end;
    margin-right: 20px;
}

.principal-claim-container{
    padding-left:20px;
    max-height: calc(100vh - 510px);
    overflow-y: auto;
}

.principal-claim-container-disabled{
  padding-left:20px;
  max-height: calc(100vh - 510px);
  overflow-y: auto;
  pointer-events: none; 
}

.claim-data {
  font: $brand_font16_bold;
  color: $brand_darkgrey;
  font-size: large;
  padding: 0px 0px 10px 0px;
}

.box-wizard-container {
  margin: 14px;
  padding: 14px;
  border: 2px solid $brand_darkblue;
  border-radius:6px;  
  color: $brand_darkblue;
}

.title-wizard-container {
  font-weight: bold;
  margin: 12px 0
}

.li-wizard-container {
  list-style-position: outside;
}

.stepper-wizard {
  margin-top: 15px;
}
.stepper-order-tracker-wizard {
  margin-top: 30px;
}
.order-tracker {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.title-wizard {
  font-weight: bold;
  margin: 12px 0;
  text-decoration: underline;
}

.wizardUploadImgContainer {
  height: 20px;
  width: 40px;
  border: 1px solid $brand_darkergrey;
  align-items: center;
  justify-content: center;
  text-align: center;
  font: $brand_font12;


  &.MuiGrid-container {
    height: 120px;
    width: 725px;
  }

  .uploadImg {
    height: 113px;
    width: 104px;
    left: 5px;
  }

  .uploadFile {
    &.MuiSvgIcon-root {
      height: 100px;
      width: 125px;
      fill: $brand_statusnavyblue;
    }
  }
}
.rework-request-item{
  font-weight: 600;
  border: 3px solid $brand_darkergrey;
  border-radius: 10px;
  margin: 5px;
  padding: 5px;
  color: orange;
  max-width: 210px;
  text-align: center;
}

.rework-request-item-selected{
  font-weight: 600;
  border: 3px solid $brand_darkergrey;
  border-radius: 10px;
  margin: 5px;
  padding: 5px;
  color: orange;
  max-width: 210px;
  text-align: center;
  background-color: $brand_darkblue;
}

.claimuploadImgWizard {
  margin: 5px 0px;
  height: 40px;
  max-width: 5px;
  border: 1px solid $brand_darkergrey;
  align-items: center;
  justify-content: center;
  text-align: center;
  font: $brand_font12;
}

.step-wizard-container{
  min-height: 750px;
}