@import "_brand";

.navItem {
    margin: 10px 0px;
    
    .addNew {
        text-align: left;
        padding: 16px 0px 0px 0px;
        margin: 16px 0px 10px 30px;
        letter-spacing: 0px;
        color: $brand_lightergrey;
        opacity: 1;
        box-shadow: 0px -1px 0px 0px $brand_white;
        cursor: pointer;
    }
}

.itemCountEmpty {
    height: 40px;
    //padding-left: 30px;
}

.itemCount {
    background-color: $brand_red;
    font-size: 11px;
    color: $brand_white;
    border-radius: 24px;
    border: 1px solid $brand_white;
    height: 24px;
    width: 24px;
    padding: 5px;

    &.threedigitsmall {
        width: 30px;
        text-align: center;
        line-height: 10px;
    }

    &.threedigitbig {
        width: 30px;
        text-align: center;
        line-height: 14px;
        position: relative;
        bottom: 50px;
        left: 10px;
        z-index: 10;
    }

    &.big {
        line-height: 14px;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin: 0px 7px;
    }

    &.small {
        line-height: 10px;
        align-items: center;
        justify-content: center;
    }

    &.itemCollapse {
        position: relative;
        bottom: 50px;
        left: 10px;
        z-index: 10;
    }
}


.link {
    z-index: 0;
    text-decoration: none;
    color: $brand_white;
    white-space: nowrap;
}

.subItems {
    width: 220px;
    //max-height: 16px;
    /* UI Properties */
    border-radius: 3px;
    opacity: 1;
    height: 0px;
    color: $brand_white;
    white-space: nowrap;

    &.expanded {
        height: auto;
        background: $brand_white 1A 0% 0% no-repeat padding-box;
    }

    &.collapsed {
        height: 0px;
        display: none;
    }

    .subItem {
        height: 16px;
        /* UI Properties */
        padding: 13px 0px;
        margin-left: 20px;
        margin-bottom: 5px;
        text-align: left;
        letter-spacing: 0px;
        color: $brand_white;
        // opacity: 1;
        font: $brand_font16_light;

        .subNavselected {
            background: $brand_lightgrey 0% 0% no-repeat padding-box;
            font-style:italic;
        }
    }
    

    .MuiButton-textPrimary {
        color: $brand_white;

        &:hover {
            color: $brand_bluehover;
        }
    }
}

.navItemHeader {
    height: 48px;
    /* UI Properties */
    //text-align: left;
    font: $brand_font_large_bold;
    letter-spacing: 0px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    // align-items: center;
    justify-content: flex-start;
    padding: 10px 18px;
    text-align: left;

    img {
        margin-right: 20px;
        height: 30px;
        width: 30px;
    }

    &.selected {
        width: 260px;
        //    width: 210px;
        height: 48px;
        /* UI Properties */
        background: $brand_lightgrey 0% 0% no-repeat padding-box;
        border-radius: 3px;
        opacity: 1;
        font: $brand_font_large_bold;
        letter-spacing: 0px;
        color: #1B4070;
    }

    &.notSelected {
        box-shadow: -1px 0px 0px 0px $brand_white;
        margin: 4px 0px;
    }

    &.collapsed {
        width: 60px;
    }
}

.hidden {
    display: none;
}