@import "_brand";

.containerCalendly {
  display: flex;
}

.dialogNotes {
  overflow-x: hidden;
  .MuiDialog-paper {
    width: 100vw;
    max-width: 1200px;
    margin: 0;
    border-radius: 5px;
  }

  .consultationNotesTitle {
    padding: 5px 24px;
    background: #F7FAFC 0 0 no-repeat padding-box;
    box-shadow: 3px 3px 6px #00000080;
    border-radius: 3px;
    opacity: 1;
  }

  .footer {
    justify-content: flex-end;
  }
}


.consultationNotesHeader {
  display: flex;
  align-items: center;
  padding: 5px 25px;
  opacity: 1;
  justify-content: space-between;
  gap: 20px;

  .notesDetails {
    padding: 10px 0;
    grid-template-columns: 1fr 1fr;
    justify-content: space-around;

    .typography {
      width: 120px;
      height: 20px;
    }

    .noteDetail {
      font: $brand_font12bold;
      margin: 3px;
      padding: 5px 10px 5px 10px;
      background-color: $brand_white;
      color: $brand_darkblue;
      border-radius: 6px;
      border: 3px solid $brand_lightgrey;
    }
  }

  .noteStatusContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .noteStatus {
      font: $brand_font12bold;
      margin: 3px;
      padding: 5px 10px 5px 10px;
      background-color: $brand_white;
      color: $brand_darkblue;
      border-radius: 6px;
      border: 3px solid $brand_lightgrey;

      .MuiSelect-select {

        padding: 0 24px;
      }
    }
  }
}






