.react-calendar {
	border:none;
}
.react-calendar__navigation {
	display: grid;
	grid-template-columns: 4fr 1fr 1fr;
}
.react-calendar__navigation__label {
	grid-column: 1;
	grid-row: 1;
}
.react-calendar__navigation__prev-button {
	grid-column: 2;
	color: #006edc;
}
.react-calendar__navigation__next-button {
	grid-column: 3;
	color: #006edc;
}
.react-calendar__navigation__prev2-button {
	display: none;
}
.react-calendar__navigation__next2-button {
	display: none;
}
.react-calendar__month-view__days__day--weekend:enabled {
	color: #000;
	font-weight: 100;
}
.react-calendar__month-view__days__day--weekend.react-calendar__tile--active  {
	color: #fff;
	font-weight: 100;
}
.react-calendar__month-view__days__day--weekend {
	color: #9E9E9E;
	font-weight: 100;
}
.react-calendar__month-view__weekdays {
	font-weight: 100;
	cursor: default !important;
	text-decoration: none;
}
.react-calendar__tile {
	border-radius: 25px;
	width: 50px;
	height: 50px;
	color: #006edc;
	background-color: #0060e610;
	font-weight: 700;
  }
  .react-calendar__tile:disabled {
	color: #9E9E9E;
	background-color: #fff;
  }
  .react-calendar__tile--now {
	background: #fff;
	padding-top: 15px;
  }
  .react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
	background: #0060d440;
  }
 .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
	background: #006edc;
	color:#fff;
	cursor: default !important;
  }
  .react-calendar__tile--now.react-calendar__tile--active {
	color:#fff;
	background: #006edc;
  }
  .react-calendar__tile--now ::after{
	content: " ";
	display: block;
	background: #006edc;
	height: 4px;
	width: 4px;
	border-radius: 2px;
	transform: translate(15px, 5px);
  }
  .react-calendar__tile--now.react-calendar__tile--active ::after{
	background: #fff;
  }