.stepsLayout {
    top: 144px;
    left: 142px;
    height: 48px;
    box-shadow: inset 3px 3px 3px #00000080;
    border: 3px solid #C2C2C2;
    opacity: 1;
}

.CreateHeader {
    /* Layout Properties */
    top: -40px;
    left: -38px;
    height: 863px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
}

// .TextBox {
//     margin-left: 1ch;
//     margin-bottom: 3ch;
//     padding-top: 20px;
//     font-size: '16px';
//     border: 2px solid transparent;
//     &:hover{
//         background-color: $brand_lightergrey;
//     }
//     &:focus{
//         background-color: $brand_lightergrey;
//     }
// }

.StepLabel {
    top: 199px;
    left: 124px;
    width: 84px;
    height: 35px;
    /* UI Properties */
    text-align: center;
    font-family: Proxima Nova;

    letter-spacing: 0px;
    color: #1B3E6F;
    opacity: 1;
}

.Close {
    /* Layout Properties */
    top: 70px;
    left: 900px;
    width: 23px;
    height: 23px;
    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    /* UI Properties */
    opacity: 1;
}

.Title {
    /* Layout Properties */
    top: 50px;
    left: 50px;
   // height: 60px;
    /* UI Properties */
    background: #F7FAFC 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #00000080;
    border-radius: 3px;
    opacity: 1;
    font: bold normal 900 24px/29px Proxima Nova;
}

.BoxDialog {
    /* Layout Properties */
    top: 381px;
    left: 50px;
    height: auto;
    width: auto;
    min-width: 1206px;
    min-height: 650px;
    box-shadow: 3px 3px 6px #00000080;
    /* UI Properties */
    opacity: 1;
    padding-top: 20px;
}

.BoxCont {
    margin-left: 1;
    margin-bottom: 1ch;

}

.StepsLayout {
    background: $brand_white 0% 0% no-repeat padding-box;
    color: $brand_darkblue;
    border: 2px solid $brand_darkblue;
    border-radius: 5px;
    font: normal normal bold 12px/12px Roboto;
    letter-spacing: 0px;
    opacity: 1;
    width: auto;

    &.small {
        width: 50px;
        height: 35px;
        border: 2px solid $brand_darkblue;
        padding-top: 3px;
        border-radius: 20px;
        color: $brand_darkblue;
        background-color: #1B3E6F;
        font: $brand_font16_button_Roboto;
    }
}

.stepperStep {

    .MuiStepConnector-lineHorizontal {
        margin: 0px -7px;
        color: $brand_darkblue;
        border: 4px solid $brand_darkblue ;//, 1px solid $brand_white outset; 
        box-shadow: 0px 3px 0px $brand_grey, 0px -3px 0px $brand_grey;
    }

    .Mui-disabled {
        color: $brand_lightgrey;
        border: 2px solid $brand_white;
        margin: -3px 0px;

        svg {
            circle {
                color: $brand_white;
            }
        }

        .MuiStepConnector-lineHorizontal {
            margin: 0px -6px;
            border: 4px solid $brand_white;
            box-shadow: 0px 3px 0px $brand_grey, 0px -3px 0px $brand_grey;
        }
    }

}

.stepperStepLabel {

    svg {
        height: 40px;
        width: 40px;
        padding: 2px;
        border: 4px solid $brand_grey;
        border-radius: 20px;
        margin: -2px 0px;

        &.Mui-completed {
            //padding: 2px;
            color: $brand_darkblue;
        }

        &.Mui-disabled {
            color: $brand_white;

            circle {
                color: $brand_white;
            }
        }

        circle {
            color: $brand_darkblue;

            .Mui-completed {
                color: $brand_darkblue;
            }
        }

        text {
            font: $brand_font16;
            color: $brand_white;
        }
    }


    .MuiStepLabel-labelContainer {
        font: $brand_font16;
        color: $brand_grey;

        .Mui-active {
            font: $brand_font16_bold;
            color: $brand_darkblue;
        }

        .Mui-completed {
            font: $brand_font16_bold;
            color: $brand_darkblue;
        }
    }

}