@import '_brand';

.patientEditModal {
    overflow: hidden;
    // border-radius: 6px;
    // border-right: 3px solid $brand_lightgrey;
    // box-shadow: 3px 3px 6px $brand_darkgrey, -3px -3px 6px $brand_darkgrey;
}

.patientEditContainer {
    overflow: hidden;
}

.header {
    border-radius: 6px 6px 0px 0px;
    border-right: 3px solid $brand_lightgrey;
    border-left: 2px solid $brand_lightgrey;
    box-shadow: 3px 3px 6px 0px $brand_darkgrey, -3px -3px 6px 0px $brand_darkgrey;
    margin-top: 20px;
    padding: 0px 20px;
    background-color: $brand_paleblue;

    @media screen and (max-width: $brand_xlarge) {
        border-radius: 5px 2px 0px 0px;
    }

    .imageContainer {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .profilePhoto {
        margin-top: 15px;
        padding: 3px;
        border-radius: 6px;
        border-right: 2px solid $brand_lightgrey;
        border-bottom: 2px solid $brand_lightgrey;
        // z-index: 10;
    }

    .nameSection {
        // margin: 30px 0px 0px 0px;
        margin-left: 20px;

        .name {
            padding-top: 20px;
            font: $brand_font16_bold;
            color: $brand_darkgrey;
        }

        .lastName {
            font: $brand_header;
            color: $brand_darkblue;
        }

        .details {
            padding: 10px 0px;

            .detail {
                font: $brand_font12bold;
                margin: 3px;
                padding: 5px 10px 5px 10px;
                background-color: $brand_white;
                color: $brand_darkblue;
                border-radius: 6px;
                border: 3px solid $brand_lightgrey;
            }
        }

        .buttons {
            border-left: 2px solid $brand_lightgrey;
            margin: 20px 0px 20px 0px;
        }
    }

    .divider {
        &.bottom {
            &.small {
                border-bottom: 2px solid $brand_lightgrey;
            }
        }

        &.top {
            &.long {
                border-top: 2px solid $brand_lightgrey;
            }
        }
    }
}

.tabSection {
    border-top: 3px solid $brand_darkblue;
    background-color: $brand_paleblue;
    border-radius: 0px 0px 6px 6px;
    box-shadow: 0px 0px 6px $brand_darkgrey, 0px 0px 6px $brand_darkgrey;
    padding: 1px;

    .tabs {
        margin: 20px 25px;
        background-color: $brand_white;
        border: 3px solid $brand_lightgrey;
        border-radius: 24px;
        box-shadow: 3px 3px 6px $brand_darkgrey inset;
        justify-content: space-between;

        .tab {
            cursor: pointer;
            padding: 5px 30px;
            margin: 7px;
            font: $brand_font13_bold;
            color: $brand_darkgrey;
            border-radius: 24px;
            border: 3px solid $brand_white;

            &:hover {
                color: $brand_white;
                background-color: $brand_darkblue;
            }

            &.active {
                border-radius: 24px;
                border: 3px solid $brand_darkblue;
                color: $brand_darkblue;

                &:hover {
                    color: $brand_white;
                    background-color: $brand_darkblue;
                }
            }
        }
    }
}

.tabSubSection {
    margin: 0px;
    padding: 0px;

    .tabs {
        border-radius: 24px;
        justify-content: space-between;

        .tab {
            cursor: pointer;
            padding: 5px 30px;
            margin: 7px;
            font: $brand_font13_bold;
            color: $brand_darkgrey;
            border-radius: 24px;
            border: 3px solid $brand_white;

            &:hover {
                color: $brand_white;
                background-color: $brand_darkblue;
            }

            &.active {
                border-radius: 24px;
                border: 3px solid $brand_darkblue;
                color: $brand_darkblue;

                &:hover {
                    color: $brand_white;
                    background-color: $brand_darkblue;
                }
            }
        }
    }
}
.sectionHdr {
    font: $brand_font16_bold;
    color: $brand_darkblue;
}

.patientDetailsTab {
    margin: 10px 0px;
    padding: 0px 25px 120px 25px;
    height: auto;
    max-height: calc(100vh - 410px);
    overflow-y: auto;

    .headerLine {
        font: $brand_font_large_bold;
        color: $brand_darkblue;
        justify-content: space-between;
        align-content: center;

        .label {
            padding: 15px 0px;
            text-align: center;
            align-items: center;
        }

        border-bottom: 3px solid $brand_lightgrey;
    }

    .viewBody {
        .MuiFormControlLabel-label {
            font: $brand_font12;
            color: $brand_lightgrey;
            text-align: start;
        }

        .switch {
            font: $brand_font16_bold;
            color: $brand_darkblue;

            .MuiFormControlLabel-label {
                font: $brand_font16_bold;
                color: $brand_darkblue;
                text-align: start;
            }
        }
    }
}

.viewTeethChart {
    margin-top: 20px;
    overflow-y: auto;
    height: calc(100vh - 561px);
}

.tabContent {
    padding: 25px;
    overflow-y: auto;
    max-height: calc(100vh - 561px);
    .dataGrid {
        &.standard {
            max-height: calc(100vh - 590px);
        }
    }
    .viewTeethChart {
        margin-top: 20px;
        overflow-y: auto;
        height: calc(100vh - 561px);
    }
}

.formBasics {
    margin: 0px 25px 25px 25px;
    .MuiFormControl-root {
        width: 90%;
    }

    &.full {
        .MuiFormControl-root {
            width: 100%;
        }
    }
    .TextBox,
    .DateBox {
        width: 95%;
        border: 2px solid $brand_bordergrey;
        &.full {
            width: 100%;

            .MuiFormControl-root {
                width: 100%;
            }
        }
        &.small {
            width: 150px;
        }
        &:hover {
            background-color: $brand_hoverblue;
        }
        &:focus-within {
            background-color: $brand_hoverblue;
        }
    }
    outline-color: none;
    outline-style: none;
    outline-width: 0px;
    fieldset: {
        border-width: 0px;
        border-style: solid;
        width: 100%;
    }
}

.formLabel {
    color: $brand_blue;
}

.listHorizontal {
    .MuiGrid-item {
        padding-left: 10px;
    }
    .MuiCheckbox-root {
        padding-left: 10px;
    }
}

.lightgreen{  
    color: $brand_ligntgreen;
   // background-color: $brand_ligntgreen;
}