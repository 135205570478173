#react-doc-viewer {
    height: 700px;
    width: 1200px;
    overflow: hidden;
    margin-top: 15px;

    @media screen and (max-width: $brand_xlarge) {
        height: 510px;
        width: 860px;
    }

    & #header-bar {
        display: none;
    }

    & #proxy-renderer {
        overflow: hidden;
    }
}

.modalAireport {
    height: 420px;
    display: flex;
    justify-content: center;
    overflow-y: auto;

    @media screen and (max-width: $brand_xlarge) {
        height: 200px;
    }
}

.noDocument {
    font: $brand_header;
    color: $brand_darkblue;
}
