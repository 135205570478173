@import "_brand";

figure {
    position: relative;
    display: inline-block;
}

figcaption {
    display: none;
    position: absolute;
    font-size: 8px;
    left: -100%;
    top: 10px;
    right: 0px;
    color: white;
    padding: 4px;
    width: 105px;
    background-color: rgb(0,0,0,0.5);
}

figure:hover img {
    opacity: .7;
}

figure:hover figcaption {
    display: block;
}

.svgGrid {
    width:40px;
    height:45px;
}

.status-58 {
    filter: brightness(0) saturate(100%) invert(21%) sepia(0%) saturate(0%) hue-rotate(227deg) brightness(96%) contrast(90%); margin-top: 15px;
}

.status-59 {
    filter: brightness(0) saturate(100%) invert(62%) sepia(12%) saturate(310%) hue-rotate(164deg) brightness(91%) contrast(96%); margin-top: 15px;
}

.status-60 {
    filter: brightness(0) saturate(100%) invert(19%) sepia(93%) saturate(2467%) hue-rotate(183deg) brightness(98%) contrast(102%); margin-top: 15px;
}

.status-61 {
    filter: brightness(0) saturate(100%) invert(71%) sepia(45%) saturate(4792%) hue-rotate(359deg) brightness(101%) contrast(107%); margin-top: 15px;
}

.status-62 {
    filter: brightness(0) saturate(100%) invert(27%) sepia(79%) saturate(7462%) hue-rotate(358deg) brightness(94%) contrast(111%); margin-top: 15px;
}

.status-133 {
    filter: brightness(0) saturate(100%) invert(76%) sepia(9%) saturate(7497%) hue-rotate(32deg) brightness(99%) contrast(101%); margin-top: 15px;
}

.status-134 {
    filter: brightness(0) saturate(100%) invert(0%) sepia(6%) saturate(24%) hue-rotate(167deg) brightness(88%) contrast(100%); margin-top: 15px;
}