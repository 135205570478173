@import "_brand";

.dashInfoBox{
    margin: 15px 0px;
    max-width: 200px;
    min-width: 200px;
    height: 150px;
    border: 2px solid $brand_darkblue;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 30%);
    border: 3px solid #CED6DF;
    border-radius: 6px;
    cursor: pointer;

    .dashHeader {
        padding: 5px;
        height: 60px;
        width:inherit;
        text-align:center;
        background-color: $brand_darkblue;
        font: $brand_font_large_bold;
        color: $brand_white;
        &.selected {
            background-color: $brand_grey;
            color: $brand_white;
        }
        border-radius: 6px;
    }

    .dashBody{
        text-align:center;
        width: inherit;
        font: $brand_header;
        border: 2px 0 0 0 solid $brand_darkblue;
        border-radius: 6px;
        
    }
}